import { FunctionComponent } from "react";

interface BasicCardProps {
  children?: string | JSX.Element;
  onClick?: () => any;
  className?: string;
  dataTestFor?: string;
}

const BasicCard: FunctionComponent<BasicCardProps> = ({
  children,
  onClick,
  className = "",
  dataTestFor,
}) => {
  return (
    <div
      className={`app-card ${onClick ? "card-hover" : ""} ${className}`}
      onClick={onClick}
      data-test="card"
      data-test-for={dataTestFor}
    >
      {children}
    </div>
  );
};

export default BasicCard;
