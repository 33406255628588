import { createEffect } from "effector";
import "whatwg-fetch";
import configuration from "src/utils/Config";
import {
  restDetailsHandler,
  restGetUniqueHandler,
  restListHandlerPagination,
  restPatchHandler,
  restWorkflowHandler,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { MentionResponseDto } from "@state/mention/dto/mention.response.dto";
import { GazProfessionalSearchCriteria } from "@state/gazProfessional/dto/gaz.professional.search.criteria";
import { SmokeDuctWorkflowRequestDto } from "@state/mention/smokeduct/dto/smoke.duct.workflow.request.dto";
import { CommentSaveRequest } from "@state/mention/smokeduct/dto/smoke.duct.save.comment.dto";
import { UpdateRgeSaveRequest } from "@state/mention/pachybride/dto/pac.hybride.save.rge.id.dto";
import { BonusSaveRequest } from "@state/mention/smokeduct/dto/smoke.duct.save.bonus.dto";

const baseUrl = configuration.backendBaseUrl;
const mentionUrl = baseUrl + "/mentions/smoke-duct";

export const requestGetMentionsSmokeDuct = createEffect({
  handler: restListHandlerPagination<Pageable<MentionResponseDto>>(mentionUrl),
});

export const requestGetMentionsSmokeDuctExportExcel = createEffect({
  handler: restGetUniqueHandler<Blob, GazProfessionalSearchCriteria>(
    `${mentionUrl}/export-excel`,
  ),
});

export const requestGetMentionSmokeDuct = createEffect({
  handler: restDetailsHandler<MentionResponseDto>(`${mentionUrl}/comment/`),
});

export const requestWorkflowMentionSmokeDuct = createEffect({
  handler: restWorkflowHandler<SmokeDuctWorkflowRequestDto, MentionResponseDto>(
    `${mentionUrl}/`,
  ),
});

export const requestUpdateMentionSmokeDuctAdditionalBonus = createEffect({
  handler: restPatchHandler<BonusSaveRequest, MentionResponseDto>(
    `${mentionUrl}/bonus/`,
  ),
});

export const requestUpdateMentionSmokeDuctCommentBO = createEffect({
  handler: restPatchHandler<CommentSaveRequest, MentionResponseDto>(
    `${mentionUrl}/commentBO/`,
  ),
});

export const requestUpdateRgeMentionSmokeDuct = createEffect({
  handler: restPatchHandler<UpdateRgeSaveRequest, MentionResponseDto>(
    `${mentionUrl}/rge/`,
  ),
});
