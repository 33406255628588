import { FunctionComponent, useMemo, useState } from "react";
import BasicModal from "@components/modals/BasicModal";
import { useTranslation } from "react-i18next";
import { WorksiteItemsResponseDto } from "@state/worksite/dto/worksite.response.dto";
import { Form, Input } from "antd";
import InputFormField from "@components/inputs/InputFormField";
import SubmitButton from "@components/buttons/SubmitButton";
import { Store } from "rc-field-form/lib/interface";
import {
  requestDownloadWorksiteResources,
  requestDownloadWorksitesResources,
  requestUpdateWorksiteBonusBill,
  requestUpdateWorksitesBonusBill,
} from "@state/worksite/WorksiteEffects";
import BasicButton from "@components/buttons/BasicButton";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { LinkOutlined } from "@ant-design/icons/lib";
import { downloadFile } from "@utils/Base64File";

interface WorkSiteBonusModalProps {
  worksites: WorksiteItemsResponseDto[];
  downloadInvoice?: boolean;
  visible: boolean;
  onCancel: () => void;
  onUpdate?: () => void;
  className?: string;
}

const WorkSiteBonusModal: FunctionComponent<WorkSiteBonusModalProps> = ({
  worksites,
  downloadInvoice = false,
  visible,
  onCancel,
  onUpdate,
  className = "",
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDownloadResourcesLoading, setIsDownloadResourcesLoading] =
    useState<boolean>(false);

  const computedInvoiceName = useMemo(() => {
    if (worksites?.length > 0) {
      const sortedWorksites = worksites.sort((a, b) =>
        new Date(a?.createdAt) > new Date(b?.createdAt) ? 1 : -1,
      );
      const firstDate = sortedWorksites[0]?.createdAt;
      const lastDate = sortedWorksites[sortedWorksites.length - 1]?.createdAt;
      const dateString =
        firstDate === lastDate ? firstDate : `${firstDate} au ${lastDate}`;
      return `${sortedWorksites[0]?.companyName} PRI GRDF MEN CF ${dateString}`;
    }
    return "";
  }, [worksites]);

  const handleUpdateBonusBill = (values: Store) => {
    if (values && worksites) {
      // setIsModalVisible(false);
      setIsLoading(true);
      if (downloadInvoice) {
        void requestUpdateWorksitesBonusBill({
          dto: {
            worksiteIds: worksites.map((worksite) => worksite.id),
            bonusBillNumber: values.bonusBillNumber as string,
          },
        })
          .then((response) => {
            if (response.ok && response.data) {
              toastSuccess(
                t<string>("worksite.details.workflow.generateInvoice.success"),
              );
              downloadFile(
                response.data.data,
                response.data.type,
                response.data.filename,
              );
              onUpdate?.();
            }
          })
          .finally(() => setIsLoading(false));
      } else {
        void requestUpdateWorksiteBonusBill({
          id: worksites[0].id,
          dto: {
            bonusBillNumber: values.bonusBillNumber as string,
          },
        }).finally(() => setIsLoading(false));
      }
    }
  };

  const handleDownloadResources = (values: Store) => {
    if (values && worksites) {
      setIsDownloadResourcesLoading(true);
      if (worksites.length > 1) {
        void requestDownloadWorksitesResources({
          dto: {
            worksiteIds: worksites.map((worksite) => worksite.id),
            companyName: worksites[0].companyName,
          },
        })
          .then((response) => {
            if (response.ok && response.data) {
              toastSuccess(
                t<string>(
                  "worksite.details.workflow.downloadResources.success",
                ),
              );
              downloadFile(
                response.data.data,
                response.data.type,
                response.data.filename,
              );
            } else {
              toastError(
                response.errorMessage ??
                  t<string>(
                    "worksite.details.workflow.downloadResources.error",
                  ),
              );
            }
          })
          .finally(() => setIsDownloadResourcesLoading(false));
      } else {
        void requestDownloadWorksiteResources(worksites[0].id)
          .then((response) => {
            if (response.ok && response.data) {
              toastSuccess(
                t<string>(
                  "worksite.details.workflow.downloadResources.success",
                ),
              );
              downloadFile(
                response.data.data,
                response.data.type,
                response.data.filename,
              );
            } else {
              toastError(
                response.errorMessage ??
                  t<string>(
                    "worksite.details.workflow.downloadResources.error",
                  ),
              );
            }
          })
          .finally(() => setIsDownloadResourcesLoading(false));
      }
    }
  };

  const writeToClipboard = () => {
    navigator.clipboard.writeText(computedInvoiceName).then(
      () => {
        toastSuccess(t<string>("worksite.details.workflow.copy.success"));
      },
      () => {
        toastError(t<string>("worksite.details.workflow.copy.error"));
      },
    );
  };

  return (
    <>
      <BasicModal
        visible={visible}
        onCancel={onCancel}
        className={`${className}`}
        footer={null}
      >
        <>
          <h3 className="text-center mb-30">
            {t<string>("worksite.modal.bonusBill.title")}
          </h3>
          <Form className="mb-0" onFinish={handleUpdateBonusBill}>
            <div className="mb-3 d-flex flex-column">
              <label>
                {t<string>(
                  "worksite.details.workflow.generateInvoice.invoice-name",
                )}
              </label>
              <div className="d-flex gap-1">
                <Input value={computedInvoiceName} />
                <BasicButton
                  icon={<LinkOutlined />}
                  onClick={writeToClipboard}
                />
              </div>
            </div>

            <div className="mb-3">
              {t<string>("worksite.modal.bonusBill.detail")}
            </div>
            <InputFormField module="worksite.details" field="bonusBillNumber" />

            <div className="d-flex gap-2">
              <div className="d-flex justify-content-center">
                <SubmitButton
                  module="worksite.details"
                  label="bonusBillNumber"
                  isSubmitting={isLoading}
                />
              </div>
              <div className="d-flex justify-content-center">
                <BasicButton
                  text={t<string>(
                    "worksite.details.workflow.downloadResources.label",
                  )}
                  isLoading={isDownloadResourcesLoading}
                  onClick={handleDownloadResources}
                />
              </div>
            </div>
          </Form>
        </>
      </BasicModal>
    </>
  );
};

export default WorkSiteBonusModal;
