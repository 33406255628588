import configuration from "@utils/Config";
import { createEffect } from "effector";
import {
  restDetailsHandler,
  restGetUniqueHandler,
  restListHandlerPagination,
  restPatchHandler,
  restPostHandler,
  restWorkflowHandler,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { WorksiteResponseDto } from "@state/worksite/dto/worksite.response.dto";
import { WorksiteWorkflowRequestDto } from "@state/worksite/dto/worksite.workflow.request.dto";
import {
  BonusBillSaveRequest,
  BonusBillsSaveRequest,
  CommentBOSaveRequest,
  CommentSaveRequest,
} from "@state/worksite/dto/worksite.save.comment.dto";
import { GazProfessionalSearchCriteria } from "@state/gazProfessional/dto/gaz.professional.search.criteria";
import { ResourceDetailsResponseDto } from "@state/resource/dto/resource.details.response.dto";
import { WorksiteGetResourcesRequest } from "@state/worksite/dto/worksite.resources.get.dto";

const baseUrl = configuration.backendBaseUrl;
const worksiteUrl = baseUrl + "/worksite";

export const requestGetWorksites = createEffect({
  handler: restListHandlerPagination<Pageable<WorksiteResponseDto>>(
    `${worksiteUrl}/BO`,
  ),
});

export const requestGetWorksite = createEffect({
  handler: restDetailsHandler<WorksiteResponseDto>(`${worksiteUrl}/BO/`),
});

export const requestWorkflowWorksite = createEffect({
  handler: restWorkflowHandler<WorksiteWorkflowRequestDto, WorksiteResponseDto>(
    `${worksiteUrl}/`,
  ),
});

export const requestGetWorksiteExportExcel = createEffect({
  handler: restGetUniqueHandler<Blob, GazProfessionalSearchCriteria>(
    `${worksiteUrl}/export-excel`,
  ),
});

export const requestUpdateWorksiteComment = createEffect({
  handler: restPatchHandler<CommentSaveRequest, WorksiteResponseDto>(
    `${worksiteUrl}/comment/`,
  ),
});

export const requestUpdateWorksiteBonusBill = createEffect({
  handler: restPatchHandler<BonusBillSaveRequest, WorksiteResponseDto>(
    `${worksiteUrl}/`,
    "/bonus-bill",
  ),
});

export const requestUpdateWorksitesBonusBill = createEffect({
  handler: restPostHandler<BonusBillsSaveRequest, ResourceDetailsResponseDto>(
    `${worksiteUrl}/bonus-bill`,
  ),
});

export const requestDownloadWorksitesResources = createEffect({
  handler: restGetUniqueHandler<
    ResourceDetailsResponseDto,
    WorksiteGetResourcesRequest
  >(`${worksiteUrl}/BO/documents`),
});

export const requestDownloadWorksiteResources = createEffect({
  handler: restDetailsHandler<ResourceDetailsResponseDto>(
    `${worksiteUrl}/BO/documents/`,
  ),
});

export const requestUpdateWorksiteCommentBO = createEffect({
  handler: restPatchHandler<CommentBOSaveRequest, WorksiteResponseDto>(
    `${worksiteUrl}/commentBO/`,
  ),
});
