import i18n from "i18next";
import { ColumnsType } from "antd/lib/table/interface";
import { RegionResponseDto } from "@state/region/dto/region.response.dto";

export const columns = (): ColumnsType<RegionResponseDto> => [
  {
    title: i18n.t<string>("mention.smokeDuct.region.columns.name"),
    key: "name",
    dataIndex: "name",
    ellipsis: false,
  },
  {
    title: i18n.t<string>(
      "mention.smokeDuct.region.columns.numberOfInitialBonus",
    ),
    key: "numberOfInitialBonus",
    dataIndex: "numberOfInitialBonus",
    ellipsis: false,
  },
  {
    title: i18n.t<string>(
      "mention.smokeDuct.region.columns.numberOfAdditionalBonus",
    ),
    key: "numberOfAdditionalBonus",
    dataIndex: "numberOfAdditionalBonus",
    ellipsis: false,
  },
];
