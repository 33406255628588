import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Modal } from "antd";
import { formItemBlockLayout } from "@utils/Constant";
import { Store } from "rc-field-form/lib/interface";
import InputNumberFormField from "@components/inputs/InputNumberFormField";
import { MentionResponseDto } from "@state/mention/dto/mention.response.dto";

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  mention?: MentionResponseDto;
  handleSubmit: (values: Store) => void;
}

export const MentionSmokeDuctAdditionalBonusModal: React.FunctionComponent<
  Props
> = ({ visible, setVisible, mention, handleSubmit }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (mention) {
      form.setFieldsValue({
        numberOfAdditionalBonus: mention.numberOfAdditionalBonus,
      });
    }
  }, [mention, form]);

  return (
    <Modal
      title={t<string>("mention.smokeDuct.details.modal.title")}
      okText={t<string>("common.confirm")}
      okButtonProps={{
        form: mention?.id ?? "add_region_form",
        htmlType: "submit",
      }}
      onCancel={() => setVisible(false)}
      cancelText={t<string>("common.cancel")}
      open={visible}
      centered
    >
      <Form
        form={form}
        id={mention?.id ?? "add_region_form"}
        {...formItemBlockLayout}
        onFinish={handleSubmit}
      >
        <div className="d-flex justify-content-between gap-3">
          <InputNumberFormField
            showLabel
            field="numberOfAdditionalBonus"
            module="mention.smokeDuct.details.modal"
          />
        </div>
      </Form>
    </Modal>
  );
};
