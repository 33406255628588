import React from "react";
import { GazProfessionalDetailsResponseDto } from "@state/gazProfessional/dto/gaz.professional.details.response.dto";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import MentionStatusTag from "@components/mention/MentionStatusTag";
import MentionELearningStatusTag from "@components/mention/MentionELearningStatusTag";
import MentionAttestationPacStatusTag from "@components/mention/MentionAttestationPacStatusTag";
import DateFormat from "@utils/DateFormat";

export interface Props {
  gazProfessional?: GazProfessionalDetailsResponseDto;
}

const MentionDetail: React.FunctionComponent<Props> = ({ gazProfessional }) => {
  const { t } = useTranslation();
  const pacHybride = gazProfessional?.pacHybride;
  const smokeDuct = gazProfessional?.smokeDuct;
  const greenGaz = gazProfessional?.greenGaz;
  const erp = gazProfessional?.gazProfessionalTertiaryDetail;

  return (
    <>
      <h4 className="mt-3">
        {t<string>("gazProfessional.mention.pacHybride.title")}
      </h4>
      {pacHybride ? (
        <>
          <Row className="mb-2">
            <Col sm={4}>
              <div>
                {t<string>("gazProfessional.mention.pacHybride.status")}
              </div>
            </Col>
            <Col>
              <MentionStatusTag status={pacHybride.status} />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col sm={4}>
              <div>
                {t<string>("gazProfessional.mention.pacHybride.statusRge")}
              </div>
            </Col>
            <Col>
              <MentionELearningStatusTag status={pacHybride.elearningStatus} />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col sm={4}>
              <div>
                {t<string>(
                  "gazProfessional.mention.pacHybride.statusElearning",
                )}
              </div>
            </Col>
            <Col>
              <MentionAttestationPacStatusTag status={pacHybride.statusRge} />
            </Col>
          </Row>
          <Row className="mb-2">
            <div>
              {t<string>("gazProfessional.mention.pacHybride.expiredAt", {
                date: DateFormat.standardDateTime(
                  new Date(pacHybride.expiredAt),
                ),
              })}
            </div>
          </Row>
        </>
      ) : (
        <div>{t<string>("gazProfessional.mention.pacHybride.noMention")}</div>
      )}

      <h4 className="mt-3">
        {t<string>("gazProfessional.mention.smokeDuct.title")}
      </h4>
      {smokeDuct ? (
        <>
          <Row className="mb-2">
            <Col sm={4}>
              <div>{t<string>("gazProfessional.mention.smokeDuct.status")}</div>
            </Col>
            <Col>
              <MentionStatusTag status={smokeDuct.status} />
            </Col>
          </Row>
        </>
      ) : (
        <div>{t<string>("gazProfessional.mention.smokeDuct.noMention")}</div>
      )}

      <h4 className="mt-3">
        {t<string>("gazProfessional.mention.greenGaz.title")}
      </h4>
      {greenGaz ? (
        <Row className="mb-2">
          <div>
            {t<string>("gazProfessional.mention.greenGaz.createdAt", {
              date: DateFormat.standardDateTime(
                new Date(gazProfessional?.greenGazAt),
              ),
            })}
          </div>
        </Row>
      ) : (
        <div>{t<string>("gazProfessional.mention.greenGaz.noMention")}</div>
      )}
      <h4 className="mt-3">{t<string>("gazProfessional.mention.erp.title")}</h4>
      {erp ? (
        erp.participatesErp ? (
          <ul>
            {erp.erp?.map((e) => (
              <li key={e.valueOf()}>{e}</li>
            ))}
          </ul>
        ) : (
          <div>{t<string>("gazProfessional.mention.erp.noErp")}</div>
        )
      ) : (
        <div>{t<string>("gazProfessional.mention.erp.noMention")}</div>
      )}
    </>
  );
};

export default MentionDetail;
