import i18n from "i18next";

type FixedAlign = boolean | "left" | "right";
export const alignRight: FixedAlign = "right";

type OrderType = "descend" | "ascend" | undefined;
export const orderAscend: OrderType = "ascend";
export const orderDescend: OrderType = "descend";

export const formItemBlockLayout = {
  labelCol: {
    xs: { span: 24 },
    xl: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    xl: { span: 24 },
  },
};
export const dateFormat = "DD/MM/YYYY";

export const dateTimeFormat = "DD/MM/YYYY à HH:mm";

export const authRedirectKey = "authRedirect";

export const DEFAULT_500_ERROR = {
  responseCode: 500,
  errorMessage: i18n.t<string>("common.error.500"),
  data: undefined,
  ok: false,
};

export const emptySearchResult = {
  numberOfElements: 0,
  totalElements: 0,
  size: 0,
  totalPages: 0,
  number: 0,
  content: [],
};

export const FIELD_LENGTH = {
  URL: 500,
  DESCRIPTION: 255,
  NAME: 200,
  TITLE: 2000,
  TEXTAREA: 5000,
  MAIL: 255,
  SHORTTITLE: 40,
};

export const LINK_DEFAULT_TITLE = undefined;
