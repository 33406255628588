import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Divider, Form, Row, Spin } from "antd";
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import InputFormField from "@components/inputs/InputFormField";
import { formItemBlockLayout } from "@utils/Constant";
import { Field } from "@type/form/field.types";
import { formUtils } from "@utils/form-utils";
import { MENTION_STATUS } from "@components/mention/MentionStatusTag";
import { MENTION_E_LEARNING_STATUS } from "@components/mention/MentionELearningStatusTag";
import { SelectFormField } from "@components/inputs/SelectFormField";
import DateFormat from "@utils/DateFormat";
import {
  requestGetMentionSmokeDuct,
  requestUpdateMentionSmokeDuctAdditionalBonus,
  requestUpdateMentionSmokeDuctCommentBO,
  requestWorkflowMentionSmokeDuct,
} from "@state/mention/smokeduct/MentionSmokeDuctEffects";
import { Store } from "rc-field-form/lib/interface";
import TextAreaFormField from "@components/inputs/TextAreaFormField";
import { MentionResponseDto } from "@state/mention/dto/mention.response.dto";
import SubmitButton from "@components/buttons/SubmitButton";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { MentionSmokeDuctAdditionalBonusModal } from "@components/modals/MentionSmokeDuctAdditionalBonusModal";

const mapMentionSmokeDuctFields = (mention: MentionResponseDto): Field[] => {
  const fields = formUtils.mapFieldsFrom(mention);

  const validatedAtField = fields.find((field) =>
    field.name.includes("validatedAt"),
  );
  const elearningValidatedAtField = fields.find((field) =>
    field.name.includes("elearningValidatedAt"),
  );
  if (validatedAtField?.value) {
    validatedAtField.value = DateFormat.standardDateIso(
      new Date(validatedAtField.value),
    );
  }
  if (elearningValidatedAtField?.value) {
    elearningValidatedAtField.value = DateFormat.standardDateIso(
      new Date(elearningValidatedAtField.value),
    );
  }

  return fields;
};

const MentionSmokeDuctScreen: FC = () => {
  const { t } = useTranslation();
  const { mentionSmokeDuctId } = useParams<{ mentionSmokeDuctId: string }>();
  const navigate = useNavigate();
  const [fields, setFields] = useState<Field[]>([]);
  const [mention, setMention] = useState<MentionResponseDto | undefined>(
    undefined,
  );
  const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (mentionSmokeDuctId) {
      void requestGetMentionSmokeDuct(mentionSmokeDuctId);
    }
  }, [mentionSmokeDuctId]);

  useEffect(() => {
    return requestGetMentionSmokeDuct.done.watch(({ result }) => {
      if (result.ok && result.data) {
        const mention = result.data;
        setFields(mapMentionSmokeDuctFields(mention));
        setMention(mention);
      } else {
        toastError(t<string>("common.notFound"));
      }
    });
  });

  useEffect(() => {
    return requestWorkflowMentionSmokeDuct.done.watch(({ result }) => {
      if (result.ok && result.data) {
        toastSuccess(t<string>("mention.smokeDuct.details.workflow.success"));
        setMention(result.data);
        form.resetFields();
        setFields(mapMentionSmokeDuctFields(result.data));
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const handleCommentBO = useCallback(
    (values: Store) => {
      if (mentionSmokeDuctId) {
        const commentBO = values.commentBO as string;
        void requestUpdateMentionSmokeDuctCommentBO({
          id: mentionSmokeDuctId,
          dto: {
            comment: commentBO,
          },
        });
        toastSuccess(t<string>("mention.messages.comment.success"));
      }
    },
    [mentionSmokeDuctId, t],
  );

  const handleSubmit = (values: Store) => {
    if (mention) {
      void requestUpdateMentionSmokeDuctAdditionalBonus({
        id: mention.id,
        dto: {
          numberOfAdditionalBonus: values.numberOfAdditionalBonus as number,
        },
      });
    }
  };

  useEffect(() => {
    return requestUpdateMentionSmokeDuctAdditionalBonus.done.watch(
      ({ result }) => {
        if (result.ok && result.data) {
          toastSuccess(
            t<string>("mention.smokeDuct.region.modal.update.success"),
          );
          setUpdateModalVisible(false);
          form.resetFields();
          setFields(mapMentionSmokeDuctFields(result.data));
          setMention(result.data);
        } else {
          toastError(result.errorMessage);
        }
      },
    );
  });

  return (
    <PageLayout title={t<string>("mention.smokeDuct.detailTitle")}>
      {!mention ? (
        <div style={{ textAlign: "center" }}>
          <Spin indicator={spinIcon} />
        </div>
      ) : (
        <>
          <Form {...formItemBlockLayout} form={form} fields={fields}>
            <div className="form-first-item-sticky-top-container">
              <div className="form-first-item-sticky-top-content">
                <div className="d-flex align-items-center justify-content-between flex-wrap px-3">
                  <div className="d-flex align-items-center justify-content-center flex-wrap">
                    {
                      <Button
                        htmlType="reset"
                        className="m-2"
                        onClick={() => navigate(-1)}
                      >
                        <ArrowLeftOutlined /> {t<string>("buttons.back")}
                      </Button>
                    }
                  </div>
                </div>
              </div>
            </div>
            <Divider orientation="left">
              <h3 className="text-secondary mb-0">
                {t<string>("mention.smokeDuct.details.infoGp")}
              </h3>
            </Divider>
            <Row>
              <Col xs={12} sm={6}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="companyName"
                  readOnly={true}
                />
              </Col>
              <Col xs={12} sm={6}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="firstName"
                  readOnly={true}
                />
              </Col>
              <Col xs={12} sm={6}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="lastName"
                  readOnly={true}
                />
              </Col>
              <Col xs={12} sm={6}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="email"
                  readOnly={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="username"
                  readOnly={true}
                />
              </Col>
              <Col xs={12} sm={6}>
                <SelectFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="status"
                  readOnly={true}
                  options={Object.values(MENTION_STATUS)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <SelectFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="elearningStatus"
                  readOnly={true}
                  options={Object.values(MENTION_E_LEARNING_STATUS)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="phone"
                  readOnly={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={8}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="validatedAt"
                  readOnly={true}
                  type="date"
                />
              </Col>
              <Col xs={12} sm={8}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="elearningValidatedAt"
                  readOnly={true}
                  type="date"
                />
              </Col>
            </Row>
            <div className="d-flex flex-column align-items-stretch flex-sm-row">
              <div className="w-100 d-flex align-items-center">
                <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
                  <h3 className="text-secondary mb-0">
                    {t<string>("mention.smokeDuct.details.bonus")}
                  </h3>
                </Divider>
              </div>
              <div className="w-100 d-flex align-items-center divider-sm-transparent">
                <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
                  <Button
                    className="btn-primary"
                    onClick={() => setUpdateModalVisible(true)}
                  >
                    {t<string>("common.edit")}
                  </Button>
                </Divider>
              </div>
            </div>
            <Row>
              <Col xs={12} sm={8}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="weight"
                  readOnly={true}
                />
              </Col>
              <Col xs={12} sm={8}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="numberOfInitialBonus"
                  readOnly={true}
                />
              </Col>
              <Col xs={12} sm={8}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="numberOfAdditionalBonus"
                  readOnly={true}
                />
              </Col>
            </Row>
          </Form>
          <Form className="mb-0" onFinish={handleCommentBO} fields={fields}>
            <div className="d-flex flex-column align-items-stretch flex-sm-row">
              <div className="w-100 d-flex align-items-center">
                <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
                  <h3 className="text-secondary mb-0">
                    {t<string>(
                      "mention.smokeDuct.details.form.fields.commentBO.label",
                    )}
                  </h3>
                </Divider>
              </div>
              <div className="w-100 d-flex align-items-center divider-sm-transparent">
                <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
                  <SubmitButton
                    module="mention.smokeDuct.details"
                    label="commentBO"
                  />
                </Divider>
              </div>
            </div>
            <TextAreaFormField
              module="mention.smokeDuct.details"
              field="commentBO"
            />
          </Form>
          <MentionSmokeDuctAdditionalBonusModal
            visible={updateModalVisible}
            setVisible={setUpdateModalVisible}
            mention={mention}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </PageLayout>
  );
};

export default MentionSmokeDuctScreen;
