import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { Button, Col, Divider, Form, Modal, Row, Spin } from "antd";
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import InputFormField from "@components/inputs/InputFormField";
import { formItemBlockLayout } from "@utils/Constant";
import { Field } from "@type/form/field.types";
import { formUtils } from "@utils/form-utils";
import { MENTION_STATUS } from "@components/mention/MentionStatusTag";
import { MENTION_E_LEARNING_STATUS } from "@components/mention/MentionELearningStatusTag";
import { SelectFormField } from "@components/inputs/SelectFormField";
import { Store } from "rc-field-form/lib/interface";
import TextAreaFormField from "@components/inputs/TextAreaFormField";
import SubmitButton from "@components/buttons/SubmitButton";
import {
  requestDownloadWorksiteResources,
  requestGetWorksite,
  requestUpdateWorksiteBonusBill,
  requestUpdateWorksiteComment,
  requestUpdateWorksiteCommentBO,
  requestWorkflowWorksite,
} from "@state/worksite/WorksiteEffects";
import {
  WorksiteItemsResponseDto,
  WorksiteResponseDto,
} from "@state/worksite/dto/worksite.response.dto";
import RadioFormField from "@components/inputs/RadioFormField";
import { useNavigate, useParams } from "react-router-dom";
import DateFormat from "@utils/DateFormat";
import {
  NUMBER_BOILER,
  TYPE_RENOVATION,
  WorksiteStatus,
} from "@utils/enums/worksite.status";
import BasicButton from "@components/buttons/BasicButton";
import UploadFormField from "@components/inputs/UploadFormField";
import { mapResourceTofileType } from "@utils/upload-utils";
import CheckboxFormField from "@components/inputs/CheckboxFormField";
import { ResourceStatus } from "@utils/enums/resource.status";
import { ResourceDetailsResponseDto } from "@state/resource/dto/resource.details.response.dto";
import { toastError, toastSuccess } from "@utils/toast-helper";
import WorkSiteBonusModal from "@components/modals/WorksiteBonusModal";
import { downloadFile } from "@utils/Base64File";

const mapWorksiteFields = (worksite: WorksiteResponseDto): Field[] => {
  const fields = formUtils.mapFieldsFrom(worksite);

  const expiredAtField = fields.find((field) =>
    field.name.includes("expiredAt"),
  );
  if (expiredAtField?.value) {
    expiredAtField.value = DateFormat.standardDateIso(
      new Date(expiredAtField.value),
    );
  }
  const bonusPaidAtField = fields.find((field) =>
    field.name.includes("bonusPaidAt"),
  );
  if (bonusPaidAtField?.value) {
    bonusPaidAtField.value = DateFormat.standardDateIso(
      new Date(bonusPaidAtField.value),
    );
  }
  return fields;
};

const { confirm } = Modal;

const WorksiteScreen: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { worksiteId } = useParams<{ worksiteId: string }>();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [fields, setFields] = useState<Field[]>([]);
  const [checkbox, setCheckbox] = useState<string[]>([]);
  const [worksite, setWorksite] = useState<WorksiteResponseDto | undefined>(
    undefined,
  );
  const [devis, setDevis] = useState<ResourceDetailsResponseDto[]>();
  const [bill, setBill] = useState<ResourceDetailsResponseDto[]>();
  const [cc2, setCc2] = useState<ResourceDetailsResponseDto[]>();
  const [rib, setRib] = useState<ResourceDetailsResponseDto[]>();
  const [bonusBill, setBonusBill] = useState<ResourceDetailsResponseDto[]>();

  const [form] = Form.useForm();

  const isInit = worksite?.worksiteStatus === WorksiteStatus.INIT;
  const isInitIneligible =
    worksite?.worksiteStatus === WorksiteStatus.INITINELIGIBLE;
  const isPendingIneligible =
    worksite?.worksiteStatus === WorksiteStatus.PENDINGINELIGIBLE;
  const isWaiting = worksite?.worksiteStatus === WorksiteStatus.WAITING;
  const isPending = worksite?.worksiteStatus === WorksiteStatus.PENDING;
  const isEligible = worksite?.worksiteStatus === WorksiteStatus.ELIGIBLE;
  const isGRDF = worksite?.worksiteStatus === WorksiteStatus.GRDF;
  const isIndividual = worksite?.typePipe === "individual";

  const [isWorksiteBonusModalVisible, setWorksiteBonusModalVisible] =
    useState<boolean>(false);
  const [isDownloadResourcesLoading, setIsDownloadResourcesLoading] =
    useState<boolean>(false);

  useEffect(() => {
    if (worksiteId) {
      void requestGetWorksite(worksiteId);
    }
  }, [worksiteId]);

  useEffect(() => {
    return requestGetWorksite.done.watch(({ result }) => {
      if (result.ok && result.data) {
        const worksite = result.data;
        setFields(mapWorksiteFields(worksite));
        setCheckbox(
          worksite.resources
            .filter((s) => s.status === "REFUSED")
            .map((s) => s.id),
        );
        if (worksite.resources?.length > 0) {
          setDevis(
            worksite.resources.filter(
              (file) => file.domain === "WORKSITE_DEVIS",
            ),
          );
          setBill(
            worksite.resources.filter(
              (file) => file.domain === "WORKSITE_BILL",
            ),
          );
          setCc2(
            worksite.resources.filter((file) => file.domain === "WORKSITE_CC2"),
          );
          setRib(
            worksite.resources.filter((file) => file.domain === "WORKSITE_RIB"),
          );
          setBonusBill(
            worksite.resources.filter(
              (file) => file.domain === "WORKSITE_BONUS_BILL",
            ),
          );
        }
        setWorksite(worksite);
      } else {
        toastError(t<string>("worksite.notFound"));
      }
    });
  });

  useEffect(() => {
    return requestWorkflowWorksite.done.watch(({ result }) => {
      setButtonLoading(false);
      if (result.ok && result.data) {
        toastSuccess(t<string>("worksite.details.workflow.success"));
        setWorksite(result.data);
        setFields(mapWorksiteFields(result.data));
      } else {
        toastError(result.errorMessage);
      }
    });
  });
  const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const confirmationPopup = async (title: string) => {
    return new Promise<boolean>((resolve) => {
      confirm({
        title: t<string>("common.confirm"),
        content: title,
        okText: t<string>("common.accept"),
        cancelText: t<string>("common.cancel"),
        centered: true,
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  const handleUpdateWorksiteStatus = useCallback(
    (targetStatus: string) => {
      if (worksiteId) {
        setButtonLoading(true);
        void requestWorkflowWorksite({
          id: worksiteId,
          dto: {
            targetStatus: targetStatus,
            ids: checkbox,
          },
        }).finally(() => setButtonLoading(false));
      }
    },
    [worksiteId, checkbox],
  );

  useEffect(() => {
    return requestUpdateWorksiteBonusBill.done.watch(({ result }) => {
      if (result.ok && result.data && worksiteId) {
        toastSuccess(t<string>("worksite.details.workflow.success"));
        setWorksite(result.data);
        void requestGetWorksite(worksiteId);
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  const handleUpdateWorksiteStatusToAccepted = useCallback(() => {
    handleUpdateWorksiteStatus(WorksiteStatus.PENDING);
  }, [handleUpdateWorksiteStatus]);

  const handleComment = useCallback(
    (values: Store) => {
      if (worksiteId) {
        const comment = values.comment as string;
        void requestUpdateWorksiteComment({
          id: worksiteId,
          dto: {
            comment: comment,
          },
        });

        toastSuccess(t<string>("worksite.messages.comment.success"));
      }
    },
    [worksiteId, t],
  );

  const handleCommentBO = useCallback(
    (values: Store) => {
      if (worksiteId) {
        const commentBO = values.commentBO as string;
        void requestUpdateWorksiteCommentBO({
          id: worksiteId,
          dto: {
            commentBO: commentBO,
          },
        });
        toastSuccess(t<string>("worksite.messages.comment.success"));
      }
    },
    [worksiteId, t],
  );

  const handleChangeCheckbox = (id: string) => {
    checkbox.includes(id)
      ? setCheckbox(checkbox.filter((s) => s !== id))
      : setCheckbox([...checkbox, id]);
  };

  const handleDownloadResouces = () => {
    if (worksiteId) {
      void requestDownloadWorksiteResources(worksiteId)
        .then((response) => {
          if (response.ok && response.data) {
            toastSuccess(
              t<string>("worksite.details.workflow.downloadResources.success"),
            );
            downloadFile(
              response.data.data,
              response.data.type,
              response.data.filename,
            );
          } else {
            toastError(
              response.errorMessage ??
                t<string>("worksite.details.workflow.downloadResources.error"),
            );
          }
        })
        .finally(() => setIsDownloadResourcesLoading(false));
    }
  };

  return (
    <PageLayout title={t<string>("worksite.detailTitle")}>
      {!worksite ? (
        <div style={{ textAlign: "center" }}>
          <Spin indicator={spinIcon} />
        </div>
      ) : (
        <>
          <Form
            {...formItemBlockLayout}
            form={form}
            fields={fields}
            onFinish={handleUpdateWorksiteStatusToAccepted}
          >
            <div className="form-first-item-sticky-top-container">
              <div className="form-first-item-sticky-top-content">
                <div className="d-flex align-items-center justify-content-between flex-wrap px-3">
                  <div className="d-flex align-items-center justify-content-center flex-wrap">
                    <Button
                      htmlType="reset"
                      className="m-2"
                      onClick={() => navigate(-1)}
                    >
                      <ArrowLeftOutlined /> {t<string>("buttons.back")}
                    </Button>
                  </div>
                  {isIndividual && (
                    <div className="d-flex align-items-center justify-content-center flex-wrap gap-2">
                      <BasicButton
                        text={t<string>(
                          "worksite.details.workflow.downloadResources.label",
                        )}
                        onClick={handleDownloadResouces}
                        isLoading={isDownloadResourcesLoading}
                      />
                      {(isPendingIneligible || isInitIneligible) && (
                        <BasicButton
                          key="cancel-validation"
                          variant="grey"
                          text={t<string>(
                            `worksite.details.workflow.cancel.${worksite.worksiteStatus}`,
                          )}
                          onClick={() => {
                            isInitIneligible
                              ? handleUpdateWorksiteStatus(WorksiteStatus.INIT)
                              : handleUpdateWorksiteStatus(
                                  WorksiteStatus.WAITING,
                                );
                          }}
                          isLoading={buttonLoading}
                        />
                      )}
                      {(isEligible || isGRDF) && (
                        <>
                          <BasicButton
                            key="validation-grdf"
                            variant="grey"
                            text={
                              isGRDF
                                ? t<string>(
                                    "worksite.details.workflow.paid.GRDF",
                                  )
                                : t<string>(
                                    "worksite.details.workflow.pay.GRDF",
                                  )
                            }
                            onClick={() => setWorksiteBonusModalVisible(true)}
                            isLoading={buttonLoading}
                            disabled={isGRDF}
                          />
                          <BasicButton
                            key="cancel-validation"
                            variant="grey"
                            text={t<string>(
                              `worksite.details.workflow.cancel.${worksite.worksiteStatus}`,
                            )}
                            onClick={() => {
                              void confirmationPopup(
                                t<string>("worksite.modal.cancelValidated"),
                              ).then((confirmed: boolean) => {
                                if (confirmed) {
                                  handleUpdateWorksiteStatus(
                                    WorksiteStatus.INIT,
                                  );
                                }
                              });
                            }}
                            isLoading={buttonLoading}
                            disabled={isGRDF}
                          />
                        </>
                      )}
                      {(isInit || isWaiting || isPending) && (
                        <>
                          <BasicButton
                            key="validate"
                            className="btn-success"
                            text={t<string>("common.accept")}
                            isLoading={buttonLoading}
                            onClick={() => {
                              void confirmationPopup(
                                t<string>("worksite.modal.validated"),
                              ).then((confirmed: boolean) => {
                                if (confirmed) {
                                  isInit
                                    ? handleUpdateWorksiteStatus(
                                        WorksiteStatus.PENDING,
                                      )
                                    : handleUpdateWorksiteStatus(
                                        WorksiteStatus.ELIGIBLE,
                                      );
                                }
                              });
                            }}
                            disabled={
                              isInit
                                ? checkbox.length !== 0
                                : checkbox.length !== 0 ||
                                  bill?.length === 0 ||
                                  cc2?.length === 0
                            }
                          />
                          <BasicButton
                            key="refused"
                            className="btn-danger"
                            text={t<string>("common.refuse")}
                            onClick={() => {
                              void confirmationPopup(
                                t<string>("worksite.modal.ineligible"),
                              ).then((confirmed: boolean) => {
                                if (confirmed) {
                                  isInit
                                    ? handleUpdateWorksiteStatus(
                                        WorksiteStatus.INITINELIGIBLE,
                                      )
                                    : handleUpdateWorksiteStatus(
                                        WorksiteStatus.PENDINGINELIGIBLE,
                                      );
                                }
                              });
                            }}
                            isLoading={buttonLoading}
                            disabled={checkbox.length === 0}
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {isIndividual && (
              <div>
                <Divider orientation="left">
                  <h3 className="text-secondary mb-0">
                    {t<string>("worksite.details.resources.title")}
                  </h3>
                </Divider>
                <div className="mb-0">
                  {t<string>("worksite.details.resources.description")}
                </div>
                <h4 className="text-secondary mb-0">
                  {t<string>("worksite.details.resources.devis")}
                </h4>

                {devis &&
                  devis.length > 0 &&
                  devis.map((devis, index) => (
                    <Row key={index.valueOf()}>
                      <Col xs={24} sm={12} lg={24} xl={12}>
                        <UploadFormField
                          key={index.valueOf()}
                          files={[mapResourceTofileType(devis)]}
                          module="worksite.details"
                          field="devis"
                          editMode={false}
                        />
                      </Col>
                      <Col xs={24} sm={12} lg={24} xl={12}>
                        {(isInit || isInitIneligible) && (
                          <CheckboxFormField
                            key={index.valueOf()}
                            module="worksite.details"
                            field={devis.id}
                            noLabel={true}
                            onChange={() => handleChangeCheckbox(devis.id)}
                            initialValue={
                              devis.status === ResourceStatus.REFUSED
                            }
                          />
                        )}
                      </Col>
                    </Row>
                  ))}
                <h4 className="text-secondary mb-0">
                  {t<string>("worksite.details.resources.rib")}
                </h4>
                {rib &&
                  rib.length > 0 &&
                  rib.map((rib, index) => (
                    <Row key={index.valueOf()}>
                      <Col xs={24} sm={12} lg={24} xl={12}>
                        <UploadFormField
                          key={index.valueOf()}
                          files={[mapResourceTofileType(rib)]}
                          module="worksite.details"
                          field="rib"
                          editMode={false}
                        />
                      </Col>
                      <Col xs={24} sm={12} lg={24} xl={12}>
                        {(isInit || isInitIneligible) && (
                          <CheckboxFormField
                            key={index.valueOf()}
                            module="worksite.details"
                            field={rib.id}
                            noLabel={true}
                            onChange={() => handleChangeCheckbox(rib.id)}
                            initialValue={rib.status === ResourceStatus.REFUSED}
                          />
                        )}
                      </Col>
                    </Row>
                  ))}

                {!isInit && !isInitIneligible && (
                  <>
                    <h4 className="text-secondary mb-0">
                      {t<string>("worksite.details.resources.bill")}
                    </h4>

                    {bill &&
                      bill.length > 0 &&
                      bill.map((bill, index) => (
                        <Row key={index.valueOf()}>
                          <Col xs={24} sm={12} lg={24} xl={12}>
                            <UploadFormField
                              key={index.valueOf()}
                              files={[mapResourceTofileType(bill)]}
                              module="worksite.details"
                              field="bill"
                              editMode={false}
                            />
                          </Col>
                          <Col xs={24} sm={12} lg={24} xl={12}>
                            {(isWaiting || isPendingIneligible) && (
                              <CheckboxFormField
                                key={index.valueOf()}
                                module="worksite.details"
                                field={bill.id}
                                noLabel={true}
                                onChange={() => handleChangeCheckbox(bill.id)}
                                initialValue={
                                  bill.status === ResourceStatus.REFUSED
                                }
                              />
                            )}
                          </Col>
                        </Row>
                      ))}
                    {bill && bill.length === 0 && (
                      <div>
                        {t<string>("worksite.details.resources.noResource")}
                      </div>
                    )}

                    <h4 className="text-secondary mb-0">
                      {t<string>("worksite.details.resources.cc2")}
                    </h4>
                    {cc2 &&
                      cc2.length > 0 &&
                      cc2.map((cc2, index) => (
                        <Row key={index.valueOf()}>
                          <Col xs={24} sm={12} lg={24} xl={12}>
                            <UploadFormField
                              key={index.valueOf()}
                              files={[mapResourceTofileType(cc2)]}
                              module="worksite.details"
                              field="cc2"
                              editMode={false}
                            />
                          </Col>
                          <Col xs={24} sm={12} lg={24} xl={12}>
                            {(isWaiting || isPendingIneligible) && (
                              <CheckboxFormField
                                key={index.valueOf()}
                                module="worksite.details"
                                noLabel={true}
                                field={cc2.id}
                                onChange={() => handleChangeCheckbox(cc2.id)}
                                initialValue={
                                  cc2.status === ResourceStatus.REFUSED
                                }
                              />
                            )}
                          </Col>
                        </Row>
                      ))}
                    {cc2 && cc2.length === 0 && (
                      <div>
                        {t<string>("worksite.details.resources.noResource")}
                      </div>
                    )}
                  </>
                )}
                {isGRDF && (
                  <>
                    <h4 className="text-secondary mb-0">
                      {t<string>("worksite.details.resources.bonusBill")}
                    </h4>
                    {bonusBill &&
                      bonusBill.length > 0 &&
                      bonusBill.map((bonusBill, index) => (
                        <Row key={index.valueOf()}>
                          <Col xs={24} sm={12} lg={24} xl={12}>
                            <UploadFormField
                              key={index.valueOf()}
                              files={[mapResourceTofileType(bonusBill)]}
                              module="worksite.details"
                              field="bonusBill"
                              editMode={false}
                            />
                          </Col>
                        </Row>
                      ))}{" "}
                  </>
                )}
              </div>
            )}
            <Divider orientation="left">
              <h3 className="text-secondary mb-0">
                {t<string>("worksite.details.infoWorksite")}
              </h3>
            </Divider>
            <InputFormField
              className="mx-2"
              showLabel
              module="worksite.details"
              field="name"
              readOnly={true}
            />
            <InputFormField
              className="mx-2"
              showLabel
              module="worksite.details"
              field="address"
              readOnly={true}
            />
            <Row gutter={10}>
              <Col xs={24} sm={12} lg={24} xl={12}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="worksite.details"
                  field="city"
                  readOnly={true}
                />
              </Col>
              <Col xs={24} sm={12} lg={24} xl={12}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="worksite.details"
                  field="zip"
                  readOnly={true}
                />
              </Col>
            </Row>
            <RadioFormField
              initialValue={worksite.typePipe}
              required
              field="typePipe"
              options={[
                {
                  value: "individual",
                  label: t<string>(
                    "worksite.details.form.radioGroup.typePipe.individual",
                  ),
                },
                {
                  value: "collectif",
                  label: t<string>(
                    "worksite.details.form.radioGroup.typePipe.collectif",
                  ),
                },
              ]}
              readOnly={true}
            />
            <Row>
              <Col xs={24} sm={12} lg={24} xl={12}>
                <SelectFormField
                  module="worksite.details"
                  field="numberBoiler"
                  showLabel
                  required
                  options={NUMBER_BOILER}
                  disabled
                />
              </Col>

              <Col xs={24} sm={12} lg={24} xl={12}>
                <SelectFormField
                  module="worksite.details"
                  field="typeRenovation"
                  showLabel
                  required
                  options={TYPE_RENOVATION}
                  disabled
                />
              </Col>
            </Row>
            {worksite.bonusPaidAt && (
              <Row>
                <Col xs={12} sm={12}>
                  <InputFormField
                    className="mx-2"
                    showLabel
                    module="worksite.details"
                    field="bonusPaidAt"
                    readOnly={true}
                    type="date"
                  />
                </Col>
              </Row>
            )}

            <Divider orientation="left">
              <h3 className="text-secondary mb-0">
                {t<string>("worksite.details.infoGP")}
              </h3>
            </Divider>
            <Row>
              <Col xs={12} sm={6}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="companyName"
                  readOnly={true}
                />
              </Col>
              <Col xs={12} sm={6}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="firstName"
                  readOnly={true}
                />
              </Col>
              <Col xs={12} sm={6}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="lastName"
                  readOnly={true}
                />
              </Col>
              <Col xs={12} sm={6}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="email"
                  readOnly={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="username"
                  readOnly={true}
                />
              </Col>
              <Col xs={12} sm={6}>
                <SelectFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="status"
                  readOnly={true}
                  options={Object.values(MENTION_STATUS)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <SelectFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="elearningStatus"
                  readOnly={true}
                  options={Object.values(MENTION_E_LEARNING_STATUS)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <InputFormField
                  className="mx-2"
                  showLabel
                  module="mention.smokeDuct.details"
                  field="phone"
                  readOnly={true}
                />
              </Col>
            </Row>
          </Form>
          {isIndividual && (
            <Form className="mb-0" onFinish={handleComment} fields={fields}>
              <div className="d-flex flex-column align-items-stretch flex-sm-row">
                <div className="w-100 d-flex align-items-center">
                  <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
                    <h3 className="text-secondary mb-0">
                      {t<string>("worksite.details.comment")}
                    </h3>
                  </Divider>
                </div>
                <div className="w-100 d-flex align-items-center divider-sm-transparent">
                  <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
                    <SubmitButton module="worksite.details" label="comment" />
                  </Divider>
                </div>
              </div>
              <TextAreaFormField module="worksite.details" field="comment" />
            </Form>
          )}
          <Form className="mb-0" onFinish={handleCommentBO} fields={fields}>
            <div className="d-flex flex-column align-items-stretch flex-sm-row">
              <div className="w-100 d-flex align-items-center">
                <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
                  <h3 className="text-secondary mb-0">
                    {t<string>("worksite.details.commentBO")}
                  </h3>
                </Divider>
              </div>
              <div className="w-100 d-flex align-items-center divider-sm-transparent">
                <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
                  <SubmitButton module="worksite.details" label="commentBO" />
                </Divider>
              </div>
            </div>
            <TextAreaFormField module="worksite.details" field="commentBO" />
          </Form>
          <WorkSiteBonusModal
            worksites={[
              {
                id: worksite.id,
                companyName: worksite.companyName,
                mentionId: worksite.mentionId,
                createdAt: worksite.createdAt,
              } as WorksiteItemsResponseDto,
            ]}
            visible={isWorksiteBonusModalVisible}
            onCancel={() => {
              setWorksiteBonusModalVisible(false);
            }}
          />
        </>
      )}
    </PageLayout>
  );
};

export default WorksiteScreen;
