import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Form, Upload } from "antd";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { UploadOutlined } from "@ant-design/icons";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { debugBase64, downloadFile, getBase64 } from "@utils/Base64File";
import { File } from "@type/resource/resource.type";
import { InternalNamePath } from "rc-field-form/lib/interface";
import {
  requestCreateResource,
  requestDeleteResource,
  requestGetResource,
  requestUpdateResource,
} from "@state/resource/ResourceEffects";
import { uploadUtils } from "@utils/upload-utils";
import { formUtils } from "@utils/form-utils";
import BasicButton from "@components/buttons/BasicButton";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import DragableUploadListItem from "@components/items/DragableUploadListItem";
import update from "immutability-helper";
import { toastError } from "@utils/toast-helper";
import { nanoid } from "nanoid";

function beforeUpload(file: RcFile, types?: string[], typesLabel?: string) {
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    toastError(i18n.t<string>("common.file.sizeError"));
  }
  const isTypeOk = types ? types.includes(file.type) : true;
  if (!isTypeOk) {
    toastError(
      i18n.t<string>("common.file.extensionError", {
        okExtensions: typesLabel,
      }),
    );
  }
  return isLt2M && isTypeOk;
}

const mapFileToUploadFile = (file: File): UploadFile => {
  return {
    uid: file.id,
    name: file.name,
    status: "done",
    url: file.base64Data && `data:${file.type};base64,${file.base64Data}`,
    size: file.size,
    type: file.type,
  };
};

interface FileUploadFieldsProps {
  editMode?: boolean;
  files: File[];
  module: string;
  field: string | InternalNamePath;
  unique?: boolean;
  types?: string[];
  formatLabel?: string;
  onChange?: (files: File[]) => void;
  setFiles?: (files: File[]) => void;
  fieldPrefix?: string;
  showLabel?: boolean;
  saveFile?: boolean;
  multiple?: boolean;
  domain?:
    | "INSTALLATION"
    | "MAINTENANCE"
    | "GLOBAL"
    | "PAC_HYBRIDE"
    | "SYNTHESIS_OFFER"
    | "PAC_HYBRIDE_RENEWAL_TECHNICAL_TRAINING"
    | "PAC_HYBRIDE_RENEWAL_COMMERCIAL_TRAINING"
    | "PAC_HYBRIDE_RENEWAL_INSTALLATION"
    | "SMOKE_DUCT"
    | "RGE_PAC_HYBRIDE"
    | "RGE_SMOKE_DUCT"
    | "FLYER_RACCORDEMENT"
    | "GAZ_MEETING";
  draggable?: boolean;
}

const UploadFormField: FunctionComponent<FileUploadFieldsProps> = ({
  editMode = true,
  files,
  setFiles,
  module,
  field,
  types,
  formatLabel,
  fieldPrefix = "",
  showLabel = false,
  saveFile,
  domain,
  onChange,
  unique = false,
  multiple = false,
  draggable,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>(
    files.map((f) => mapFileToUploadFile(f)),
  );

  const i18nField = formUtils.geti18nFieldFromField(field);

  const previewOrDownloadFromFile = (uid: string): void => {
    const file = files && files.find((f) => f.id === uid);
    if (file && file.id) {
      void requestGetResource(file.id).then((resource) => {
        if (resource.ok && resource.data) {
          if (file.type === "application/pdf") {
            debugBase64(resource.data.data, file.type);
          } else {
            downloadFile(resource.data.data, file.type, file.name);
          }
        }
      });
    }
  };

  useEffect(() => {
    if (files) {
      setFileList(files.map((f) => mapFileToUploadFile(f)));
    }
  }, [files]);

  const uploadButton = (
    <BasicButton
      disabled={unique && fileList.length >= 1}
      isLoading={loading}
      variant="secondary"
      icon={<UploadOutlined />}
      text={t<string>("buttons.import")}
    />
  );

  const uploadProps = {
    showUploadList: {
      showPreviewIcon: true,
      showRemoveIcon: editMode,
    },
    customRequest({ file }: UploadRequestOption) {
      const rcFile = file as RcFile;
      if (saveFile && domain) {
        const formData = new FormData();
        formData.append("file", rcFile);
        formData.append("domain", domain);
        void requestCreateResource({
          dto: formData,
        }).then((result) => {
          if (result.ok && result.data) {
            const resource = result.data;
            setFileList((currentList) => [
              ...(multiple ? currentList : []),
              {
                uid: resource.id,
                name: resource.filename,
                status: "done",
                size: resource.size,
                type: resource.type,
              },
            ]);
            if (setFiles) {
              setFiles([
                ...files,
                {
                  id: resource.id,
                  name: resource.filename,
                  size: resource.size,
                  type: resource.type,
                },
              ]);
            }
            if (onChange) {
              onChange([
                ...files,
                {
                  id: resource.id,
                  name: resource.filename,
                  size: resource.size,
                  type: resource.type,
                },
              ]);
            }
          }
        });
      } else if (setFiles) {
        getBase64(rcFile, (fileUrl) => {
          const request: File = {
            id: nanoid(),
            name: rcFile.name,
            base64Data: fileUrl as string,
            size: rcFile.size,
            type: rcFile.type,
          };

          const newFiles = files ? [...files] : [];
          newFiles.push(request);
          setFiles(newFiles);
          if (onChange) {
            onChange(newFiles);
          }
          setLoading(false);
        });
      }
      setLoading(false);
      return {};
    },
  };

  const handleRemoveFile = (file: UploadFile) => {
    const removingFile = fileList.find((f) => f.uid === file.uid);
    if (removingFile) {
      const newValues = [...fileList.filter((f) => f.uid !== removingFile.uid)];
      setFileList(newValues);
      if (onChange) {
        onChange([...files.filter((f) => f.id !== removingFile.uid)]);
      }
      void requestDeleteResource({
        dto: {
          id: removingFile.uid,
        },
      });
    }
  };

  const handlePreview = (file: UploadFile) => {
    if (file.url) {
      debugBase64(file.url);
    } else {
      previewOrDownloadFromFile(file.uid);
    }
  };

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = files[dragIndex];
      if (setFiles) {
        setFiles(
          update(files, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragRow],
            ],
          }),
        );
      }
      void requestUpdateResource({
        id: files[dragIndex].id,
        dto: {
          index: hoverIndex,
        },
      });
    },
    [files, setFiles],
  );

  const renderedLabel = () => {
    return <span>{t<string>(`${module}.form.fields.${i18nField}.label`)}</span>;
  };

  const renderedItem = () => (
    <Form.Item
      className="csn-upload"
      name={
        typeof field === "object" ? field : `${fieldPrefix}${String(field)}`
      }
      label={showLabel ? renderedLabel() : ""}
      rules={
        fileList && fileList.length >= 1
          ? []
          : [
              {
                required: true,
                message: t<string>("common.empty_error_field"),
              },
              {
                validator: uploadUtils.validateUploadFormField,
                message: t<string>("common.empty_error_field"),
              },
            ]
      }
    >
      <Upload
        {...uploadProps}
        fileList={fileList}
        beforeUpload={(file: RcFile) => beforeUpload(file, types, formatLabel)}
        multiple={multiple}
        onPreview={handlePreview}
        name={`${fieldPrefix}${String(field)}`}
        onRemove={(file) => handleRemoveFile(file)}
        id={`${fieldPrefix}${String(field)}`}
        itemRender={
          draggable
            ? (originNode, file, currFileList) => (
                <DragableUploadListItem
                  originNode={originNode}
                  file={file}
                  fileList={currFileList}
                  moveRow={moveRow}
                />
              )
            : undefined
        }
      >
        {editMode && uploadButton}
      </Upload>
    </Form.Item>
  );

  return (
    <>
      {draggable ? (
        <DndProvider backend={HTML5Backend}>{renderedItem()}</DndProvider>
      ) : (
        renderedItem()
      )}
    </>
  );
};

export default UploadFormField;
