import { JSX, useEffect, useMemo } from "react";
import { ROUTES } from "@routes/Routes";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { requestGetUserInfo } from "@state/auth/AuthEffects";
import { setUser } from "@state/auth/AuthEvents";
import { Spin } from "antd";

const AuthLayout = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useStore(authentifiedStore);

  const loginRedirect = useMemo(
    () =>
      ROUTES.auth.login.generate({
        callback: location.pathname,
      }),
    [location],
  );

  useEffect(() => {
    void requestGetUserInfo({}).then((ar) => {
      if (ar.ok && ar.data) {
        setUser(ar.data);
      } else {
        navigate(loginRedirect);
      }
    });
  }, [navigate, loginRedirect]);

  if (!isAuthenticated?.user) {
    return (
      <div className="h-100 flex-grow-1 d-flex align-items-center justify-content-center">
        <Spin />
      </div>
    );
  }

  return <Outlet />;
};

export default AuthLayout;
