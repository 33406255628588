import { createEffect } from "effector";
import "whatwg-fetch";
import configuration from "src/utils/Config";
import { restListHandler, restUpdateHandler } from "@utils/rest/RestClient";
import { RegionResponseDto } from "@state/region/dto/region.response.dto";
import { RegionSaveRequestDto } from "@state/region/dto/request/region.request";

const baseUrl = configuration.backendBaseUrl;
const regionUrl = baseUrl + "/region";

export const requestGetRegions = createEffect({
  handler: restListHandler<RegionResponseDto>(regionUrl),
});

export const requestUptadeRegion = createEffect({
  handler: restUpdateHandler<RegionSaveRequestDto, RegionResponseDto>(
    `${regionUrl}/`,
    "/bonus",
  ),
});
