import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Modal } from "antd";
import { formItemBlockLayout } from "@utils/Constant";
import { Store } from "rc-field-form/lib/interface";
import { RegionResponseDto } from "@state/region/dto/region.response.dto";
import InputNumberFormField from "@components/inputs/InputNumberFormField";

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  region?: RegionResponseDto;
  handleSubmit: (values: Store) => void;
}

export const RegionModal: React.FunctionComponent<Props> = ({
  visible,
  setVisible,
  region,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (region) {
      form.setFieldsValue({
        numberOfInitialBonus: region.numberOfInitialBonus,
        numberOfAdditionalBonus: region.numberOfAdditionalBonus,
      });
    }
  }, [region, form]);

  return (
    <Modal
      title={t<string>("mention.smokeDuct.region.modal.title", {
        region: region?.name,
      })}
      okText={t<string>("common.confirm")}
      okButtonProps={{
        form: region?.id ?? "add_region_form",
        htmlType: "submit",
      }}
      onCancel={() => setVisible(false)}
      cancelText={t<string>("common.cancel")}
      open={visible}
      centered
    >
      <Form
        form={form}
        id={region?.id ?? "add_region_form"}
        {...formItemBlockLayout}
        onFinish={handleSubmit}
      >
        <div className="d-flex justify-content-between gap-3">
          <>
            <InputNumberFormField
              showLabel
              field="numberOfInitialBonus"
              module="mention.smokeDuct.region.modal"
            />
            <InputNumberFormField
              showLabel
              field="numberOfAdditionalBonus"
              module="mention.smokeDuct.region.modal"
            />
          </>
        </div>
      </Form>
    </Modal>
  );
};
