import { JSX } from "react";
import { OptionType } from "@type/form/field.types";
import {
  StopOutlined,
  SettingOutlined,
  SearchOutlined,
} from "@ant-design/icons";

export enum ProfileEnum {
  ADMIN = "ADMIN",
  CMS = "CMS",
  SERVICES = "SERVICES",
}

export interface UserProfileProperties {
  id: string;
  label: string;
  icon: JSX.Element;
  description: string;
}

export const userProfilesProperties: Record<
  ProfileEnum,
  UserProfileProperties
> = {
  [ProfileEnum.ADMIN]: {
    id: ProfileEnum.ADMIN,
    label: "users.labels.profiles.ADMIN",
    description: "users.labels.profiles.ADMIN.description",
    icon: <SettingOutlined />,
  },
  [ProfileEnum.CMS]: {
    id: ProfileEnum.CMS,
    label: "users.labels.profiles.CMS",
    description: "users.labels.profiles.CMS.description",
    icon: <SearchOutlined />,
  },
  [ProfileEnum.SERVICES]: {
    id: ProfileEnum.SERVICES,
    label: "users.labels.profiles.SERVICES",
    description: "users.labels.profiles.SERVICES.description",
    icon: <StopOutlined />,
  },
} as const;

// export const PROFILES = [
//   {
//     value: "ADMIN",
//     label: "users.labels.profiles.ADMIN",
//   },
//   {
//     value: "SERVICES",
//     label: "users.labels.profiles.SERVICES",
//   },
//   {
//     value: "CMS",
//     label: "users.labels.profiles.CMS",
//   },
// ] as OptionType[];

export const PROFILES = Object.keys(ProfileEnum)
  .map((profile) => profile as ProfileEnum)
  .map((profileId) => ({
    value: profileId,
    label: userProfilesProperties[profileId].label,
  })) as OptionType[];
