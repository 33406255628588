import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  requestAvailableUsers,
  requestSelectUser,
} from "@state/e2e/E2eEffects";
import { ROUTES } from "@routes/Routes";
import { UserItemResponseDto } from "@state/users/dto/response/user.item.response.dto";
import { toastError } from "@utils/toast-helper";
import BasicCard from "@components/cards/BasicCard";
import PageLayout from "@components/layouts/PageLayout";
import { getProfilePropertiesFrom } from "@utils/profile-helper";
import { UserStatus } from "@utils/enums/user.status.enum";

const SelectUserScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [availableUsers, setAvailableUsers] = useState<UserItemResponseDto[]>(
    [],
  );

  useEffect(() => {
    void requestAvailableUsers({});
  }, []);

  useEffect(() => {
    return requestAvailableUsers.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setAvailableUsers(result.data);
      } else {
        toastError(t<string>("Erreur lors du chargement des utilisateurs"));
      }
    });
  });

  const onClick = (userEmail: string) => {
    void requestSelectUser(userEmail).then((result) => {
      if (result.ok) {
        navigate(ROUTES.admin.home.generate());
      }
    });
  };

  /* eslint-disable @typescript-eslint/naming-convention */
  const statusMapping: Record<UserStatus, boolean> = {
    INIT: true,
    ACTIVE: true,
    DISABLED: false,
    STRUCK_OFF: false,
  };
  /* eslint-enable @typescript-eslint/naming-convention */

  return (
    <PageLayout noSideMenu title="Utilisateurs">
      <>
        <div className="text-center">
          Sélectionner un utilisateur afin de vous connecter avec son profil
        </div>
        <div className="d-flex align-items-center justify-content-center flex-wrap mt-8">
          {availableUsers.map((user) => (
            <BasicCard
              className={`m-8 card-dashboard ${
                statusMapping[user.status] ? "" : "card-disabled"
              }`}
              key={user.email}
              onClick={() => {
                if (statusMapping[user.status]) {
                  onClick(user.email);
                } else {
                  toastError(
                    "L'utilisateur n'a pas accès à l'application (compte bloqué, inactif, etc.)",
                  );
                }
              }}
              dataTestFor={`user-${user.email}`}
            >
              <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="mt-24">
                  <span
                    key={`icon_${getProfilePropertiesFrom(user.profile).id}`}
                  >
                    {getProfilePropertiesFrom(user.profile).icon}
                  </span>
                </div>
                <div className="mt-24 mb-8 dashboard-card-text text-center">
                  {user.name}
                </div>
                <div className="mb-24">
                  <span
                    key={`card_${getProfilePropertiesFrom(user.profile).id}`}
                  >
                    {t(getProfilePropertiesFrom(user.profile).label)}
                    <br />
                  </span>
                </div>
              </div>
            </BasicCard>
          ))}
        </div>
      </>
    </PageLayout>
  );
};

export default SelectUserScreen;
