enum RoutePrefixes {
  USERS = "/users",
  CATEGORIES = "/categories",
  PAGES = "/pages",
  LEADS = "/leads",
  CONTACTS = "/contacts",
  GAZPROFESSIONALS = "/gaz-professionals",
  ORGANISATONPROFESSIONALS = "/organisation-professionals",
  MENTIONSPACHYBRIDE = "/mentions-pac-hybride",
  MENTIONSSMOKEDUCT = "/mentions-smoke-duct",
  WORKSITES = "/worksites",
  E2E = "/e2e",
}

export const ROUTES = {
  auth: {
    login: {
      generate: (options?: { callback: string }): string =>
        `/login${options && options.callback ? "?c=" + options.callback : ""}`,
    },
    forgotPassword: {
      generate: (): string => "/forgot-password",
    },
    initPassword: {
      generate: (): string => "/init-password",
    },
    recoverPassword: {
      generate: (): string => "/recover-password",
    },
  },
  admin: {
    home: {
      generate: (): string => "/",
    },
    users: {
      add: {
        generate: (): string => `${RoutePrefixes.USERS}/add`,
      },
      details: {
        generate: (id: string): string => `${RoutePrefixes.USERS}/${id}`,
      },
      imports: {
        generate: (): string => `${RoutePrefixes.USERS}/import`,
      },
      list: {
        generate: (): string => `${RoutePrefixes.USERS}`,
      },
    },
  },
  management: {
    endpoints: {
      generate: (): string => "/endpoints",
    },
    tasks: {
      list: {
        generate: (): string => "/tasks",
      },
    },
    mails: {
      generate: (): string => "/mails",
    },
    components: {
      generate: (): string => "/components",
    },
  },
  cms: {
    categories: {
      add: {
        generate: (): string => `${RoutePrefixes.CATEGORIES}/add`,
      },
    },
    pages: {
      add: {
        generate: (): string => `${RoutePrefixes.PAGES}/add`,
      },
      details: {
        generate: (pageId: string): string =>
          `${RoutePrefixes.PAGES}/${pageId}`,
      },
      list: {
        generate: (): string => `${RoutePrefixes.PAGES}`,
      },
    },
    structure: {
      details: {
        generate: (): string => "/structure",
      },
    },
    keywords: {
      details: {
        generate: (): string => "/keywords",
      },
    },
  },
  leads: {
    details: {
      generate: (leadId: string): string => `${RoutePrefixes.LEADS}/${leadId}`,
    },
    list: {
      generate: (): string => `${RoutePrefixes.LEADS}`,
    },
  },
  leadsEvent: {
    list: {
      generate: () => "/leads-lp",
    },
  },
  contacts: {
    details: {
      generate: (contactId: string): string =>
        `${RoutePrefixes.CONTACTS}/${contactId}`,
    },
    list: {
      generate: (): string => `${RoutePrefixes.CONTACTS}`,
    },
  },
  gazProfessionals: {
    details: {
      generate: (yoocanId: number | string): string =>
        `${RoutePrefixes.GAZPROFESSIONALS}/${yoocanId}`,
    },
    list: {
      generate: (): string => `${RoutePrefixes.GAZPROFESSIONALS}`,
    },
  },
  organisationProfessionals: {
    details: {
      generate: (yoocanId: number | string): string =>
        `${RoutePrefixes.ORGANISATONPROFESSIONALS}/${yoocanId}`,
    },
    list: {
      generate: (): string => `${RoutePrefixes.ORGANISATONPROFESSIONALS}`,
    },
  },
  mentionsPacHybride: {
    details: {
      generate: (mentionPacHybrideId: string): string =>
        `${RoutePrefixes.MENTIONSPACHYBRIDE}/${mentionPacHybrideId}`,
    },
    list: {
      generate: (): string => `${RoutePrefixes.MENTIONSPACHYBRIDE}`,
    },
  },
  mentionsSmokeDuct: {
    details: {
      generate: (mentionSmokeDuctId: string): string =>
        `${RoutePrefixes.MENTIONSSMOKEDUCT}/${mentionSmokeDuctId}`,
    },
    list: {
      generate: (): string => `${RoutePrefixes.MENTIONSSMOKEDUCT}`,
    },
  },
  mentionsGreenGaz: {
    list: {
      generate: (): string => "/mentions-green-gaz",
    },
  },
  worksites: {
    details: {
      generate: (worksiteId: string): string =>
        `${RoutePrefixes.WORKSITES}/${worksiteId}`,
    },
    list: {
      generate: (): string => `${RoutePrefixes.WORKSITES}`,
    },
  },
  resources: {
    list: {
      generate: (): string => "/resources",
    },
  },
  gazProm: {
    generate: (): string => "/mois-du-chauffage",
  },
  organizationProfessional: {
    generate: (): string => "/organisation-professional",
  },
  e2e: {
    users: {
      generate: (): string => `${RoutePrefixes.E2E}/users`,
    },
  },
  webinar: {
    generate: (): string => "/webinar",
  },
};
