import { FC, useCallback, useEffect, useState } from "react";
import { Divider, Empty, Table } from "antd";
import { useTranslation } from "react-i18next";
import { SpinIcon } from "@components/icons/SpinIcon";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { RegionResponseDto } from "@state/region/dto/region.response.dto";
import {
  requestGetRegions,
  requestUptadeRegion,
} from "@state/region/RegionEffects";
import { columns } from "@components/lists/RegionColumns";
import { RegionModal } from "@components/modals/RegionModal";
import { Store } from "rc-field-form/lib/interface";

const RegionDistributionBonus: FC = () => {
  const { t } = useTranslation();

  const [regions, setRegions] = useState<RegionResponseDto[]>([]);

  const [region, setRegion] = useState<RegionResponseDto>();

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);
  const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);

  const fetchPages = useCallback(() => {
    setDataHasLoaded(false);
    requestGetRegions({})
      .catch(() => {
        toastError(t<string>("notification.void messages.error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [t, region]);

  useEffect(() => {
    fetchPages();
  }, [fetchPages]);

  useEffect(() => {
    return requestGetRegions.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setRegions(result.data);
      }
    });
  });

  const handleSubmit = (values: Store) => {
    if (region) {
      void requestUptadeRegion({
        id: region.regionId,
        dto: {
          numberOfAdditionalBonus: values.numberOfAdditionalBonus as number,
          numberOfInitialBonus: values.numberOfInitialBonus as number,
        },
      });
    }
  };

  useEffect(() => {
    return requestUptadeRegion.done.watch(({ result }) => {
      if (result.ok && result.data) {
        toastSuccess(
          t<string>("mention.smokeDuct.region.modal.update.success"),
        );
        setUpdateModalVisible(false);
        setRegions([
          ...regions.filter(
            (region) => region.regionId !== result?.data?.regionId,
          ),
          result.data,
        ]);
      } else {
        toastError(t<string>("mention.smokeDuct.region.modal.update.error"));
      }
    });
  });

  return (
    <>
      <div className="d-flex flex-column align-items-stretch flex-sm-row">
        <div className="w-100 d-flex align-items-center">
          <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
            <h3 className="text-secondary mb-0">
              {t<string>("mention.smokeDuct.region.title")}
            </h3>
          </Divider>
        </div>
      </div>
      <Table
        style={{ whiteSpace: "break-spaces" }}
        locale={{
          emptyText: <Empty description={t<string>("pages.table.noData")} />,
        }}
        loading={{ spinning: !dataHasLoaded, indicator: SpinIcon }}
        columns={columns()}
        dataSource={regions.sort((a, b) => a.name.localeCompare(b.name))}
        showSorterTooltip={false}
        rowKey={(record) => record.id}
        rowClassName="cursor-pointer"
        onRow={(record) => {
          return {
            onClick: () => {
              setRegion(record);
              setUpdateModalVisible(true);
            },
          };
        }}
      />
      <RegionModal
        visible={updateModalVisible}
        setVisible={setUpdateModalVisible}
        region={region}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default RegionDistributionBonus;
